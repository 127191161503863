import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useSearchParams } from 'react-router-dom';
import InformativeCard from '#/components/shared/informative-card';
import useLocales from '#/hooks/useLocales';

interface OptionsColumnProps {
  title: string;
  options: {
    value: string;
    title: string;
  }[];
  isLifeSituations?: boolean;
}

export default function OptionsColumn({
  title,
  options,
  isLifeSituations = false,
}: OptionsColumnProps) {
  const [, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const { translate } = useLocales();
  const theme = useTheme();

  const handleServiceSelect = (redirectLink: string) => {
    const subscription_package = state?.subscription_package;
    setSearchParams({
      redirectLink,
      ...(subscription_package && { subscription_package }),
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        '& > div': {
          marginTop: 1,
        },
      }}
    >
      <Typography variant="subtitle1">{String(translate(title))}</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr',
            md: 'repeat(2, 1fr)',
          },
          gap: 2,
        }}
      >
        {options.map((topic) => (
          <InformativeCard
            sx={{
              backgroundColor: isLifeSituations
                ? theme.palette.primary.main
                : theme.palette.background.paper,
            }}
            cardTitle={String(translate(topic.title))}
            key={topic.value}
            textSx={{
              color: isLifeSituations ? 'white' : 'black',
            }}
            onServiceSelect={() => handleServiceSelect(topic.value)}
          />
        ))}
      </Box>
    </Box>
  );
}
