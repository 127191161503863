import { Box, Typography } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import Iconify from '#/components/shared/ui/Iconify';

interface InformativeCardProps {
  cardTitle: string;
  sx?: SxProps;
  textSx?: SxProps;
  onServiceSelect?: VoidFunction;
}

export default function InformativeCard({
  cardTitle,
  sx,
  textSx,
  onServiceSelect,
}: InformativeCardProps) {
  const theme = useTheme();
  return (
    <Box
      onClick={() => onServiceSelect?.()}
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        padding: theme.spacing(2),
        position: 'relative',
        height: 100,
        cursor: 'pointer',
      }}
    >
      <Typography
        sx={{
          ...textSx,
        }}
        // noWrap
      >
        {cardTitle}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          bottom: 2,
          right: 10,
        }}
      >
        <Iconify
          icon="el:play-alt"
          sx={{
            ...textSx,
          }}
          width={24}
          height={24}
        />
      </Box>
    </Box>
  );
}
