import { Container, Typography, Stack, Card, Button } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import IntroHead from '#/components/shared/IntroHead';
import useLocales from '#/hooks/useLocales';
import { FormProvider } from '#/components/shared/hook-form';
import { stepsContent } from '#/components/pages/PensionProvision/onboarding/data';

const STEPS_TITLES = [
  'pensionProvision.onboarding.stepOne.title',
  'pensionProvision.onboarding.stepTwo.title',
  'pensionProvision.onboarding.stepThree.title',
];

export default function Onboarding() {
  const [activeStep, setActiveStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const { translate, currentLang } = useLocales();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (searchParams.get('redirectLink')) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSearchParams({});
    } else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const defaultValues = useMemo(
    () => ({
      provision_for: '',
      birthday: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile_phone: '',
      reminder_frequency: 'disabled',
      gender: '',
      language: currentLang.value,
    }),
    [currentLang]
  );

  const methods = useForm({
    defaultValues,
    mode: 'onBlur',
  });
  const { handleSubmit } = methods;

  const onSubmit = () => {};

  useEffect(() => {
    if (searchParams.get('redirectLink')) {
      setActiveStep(2);
    } else if (activeStep === 2 && !searchParams.get('redirectLink')) {
      setActiveStep(1);
    }
  }, [searchParams, activeStep]);

  const translatedStepsData = stepsContent?.map((step) => ({
    content: step.content,
  }));

  const currentTitle = translate(STEPS_TITLES[activeStep]);

  return (
    <Container maxWidth="lg" sx={{ marginY: 12 }}>
      <IntroHead title={String(currentTitle)} description="" />
      <Typography
        variant="body2"
        gutterBottom
        textAlign="center"
        color="primary"
        sx={{ marginBottom: 6 }}
      >
        {String(translate('pensionProvision.alreadyHaveAcc'))} &nbsp;
        <Link
          to="/auth/login"
          style={{
            color: '#3366FF',
          }}
        >
          {String(translate('pensionProvision.signIn'))}
        </Link>
      </Typography>
      <Stack sx={{ width: '100%', marginTop: 3 }} spacing={4}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ p: 3, mt: 4 }}>
            {translatedStepsData[activeStep]?.content()}
            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{ marginTop: 4 }}
              spacing={2}
            >
              {activeStep > 0 && (
                <Button variant="outlined" color="primary" onClick={handleBack}>
                  <Typography>{String(translate('global.back'))}</Typography>
                </Button>
              )}
              {activeStep !== 1 && (
                <LoadingButton variant="contained" onClick={handleNext}>
                  <Typography>
                    {activeStep === translatedStepsData.length - 1
                      ? String(translate('global.finish'))
                      : String(translate('global.next'))}
                  </Typography>
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </FormProvider>
      </Stack>
    </Container>
  );
}
