import { Box } from '@mui/material';
import {
  LIFE_SITUATIONS,
  PROVISION_TOPICS,
} from '#/components/pages/PensionProvision/onboarding/data';
import OptionsColumn from '#/components/pages/PensionProvision/onboarding/steps/options-column';

export default function SecondStep() {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 2,
      }}
    >
      <OptionsColumn
        title="pensionProvision.onboarding.stepTwo.provisionTopics"
        options={PROVISION_TOPICS}
      />
      <OptionsColumn
        title="pensionProvision.onboarding.stepTwo.lifeSituations"
        options={LIFE_SITUATIONS}
        isLifeSituations
      />
    </Box>
  );
}
