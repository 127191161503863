import { Box, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import { coreQuestions } from '#/components/pages/PensionProvision/onboarding/data';
import RHFStyledRadioGroup from '#/components/shared/hook-form/RHFStyledRadioGroup';
import { RHFDatePicker } from '#/components/shared/hook-form';

export default function FirstStep() {
  const { translate } = useLocales();
  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const translatedQuestions = coreQuestions.map((question, i) => ({
    ...question,
    question: String(
      translate(`pensionProvision.onboarding.stepOne.questions.${i}.question`)
    ),
    answers: question.answers?.map((answer, j) => ({
      ...answer,
      label: String(
        translate(
          `pensionProvision.onboarding.stepOne.questions.${i}.options.${j}.label`
        )
      ),
    })),
  }));

  return (
    <Box
      sx={{
        width: '100%',
        '& > div': {
          marginTop: 3,
        },
      }}
    >
      <Typography variant="subtitle1" fontWeight="light">
        {String(translate('pensionProvision.onboarding.stepOne.subtitle'))}
      </Typography>
      <Stack spacing={3}>
        {translatedQuestions.map((question) => (
          <div
            key={question.questionKey}
            ref={(ref) => {
              refs.current[`${question.questionKey}`] = ref;
            }}
          >
            <Stack direction="column" spacing={2}>
              {question.answers && (
                <RHFStyledRadioGroup
                  name={question.questionKey}
                  question={{
                    questionKey: question.questionKey,
                    question: question.question,
                    answers: question?.answers,
                  }}
                />
              )}
              {question.children && question.children.content()}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
}

export function Birthday() {
  const { translate } = useLocales();
  const { watch } = useFormContext();

  const forMyself = watch('provision_for') === 'myself';

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">
        {String(
          translate(
            'pensionProvision.onboarding.stepOne.questions.1.question',
            {
              referringTo: translate(
                `pensionProvision.onboarding.stepOne.${
                  forMyself ? 'myBirthday' : 'theirBirthday'
                }`
              ),
            }
          )
        )}
      </Typography>
      <RHFDatePicker
        variant="outlined"
        name="birthday"
        label={String(translate('global.formLabels.date_of_birth'))}
      />
    </Stack>
  );
}
