import FirstStep, {
  Birthday,
} from '#/components/pages/PensionProvision/onboarding/steps/first-step';
import SecondStep from '#/components/pages/PensionProvision/onboarding/steps/second-step';
import ThirdStep from '#/components/pages/PensionProvision/ProvisionStep2';

export const stepsContent = [
  {
    content: () => <FirstStep />,
  },
  {
    content: () => <SecondStep />,
  },
  {
    content: () => <ThirdStep />,
  },
];

export const coreQuestions = [
  {
    id: 1,
    questionKey: 'provision_for',
    answers: [{ value: 'myself' }, { value: 'family_member' }],
  },
  {
    id: 2,
    questionKey: 'birthday',
    children: { content: () => <Birthday /> },
  },
];

export const PROVISION_TOPICS = [
  {
    value: 'vorsorgeauftrag',
    title: 'global.services.powerOfAttorney',
  },
  {
    value: 'patientenverfuegung',
    title: 'global.services.livingWill',
  },
  {
    value: 'testament',
    title: 'global.services.lastWill',
  },
  {
    value: 'ehevertrag',
    title: 'global.services.marriageContract',
  },
  {
    value: 'digitaler-nachlass',
    title: 'global.services.digitalFootprint',
  },
  {
    value: 'vermoegens-und-sachregister',
    title: 'global.services.registerAssets',
  },
  {
    value: 'finanzberater',
    title:
      'pensionProvision.onboarding.stepTwo.provisionTopicsOptions.financialAdvise',
  },
  {
    value: 'rechtsberater',
    title:
      'pensionProvision.onboarding.stepTwo.provisionTopicsOptions.legalAdvise',
  },
];

export const LIFE_SITUATIONS = [
  {
    value: 'marriage',
    title: 'pensionProvision.onboarding.stepTwo.lifeSituationsOptions.marriage',
  },
  {
    value: 'birthOfChild',
    title:
      'pensionProvision.onboarding.stepTwo.lifeSituationsOptions.birthOfChild',
  },
  {
    value: 'purchaseOfRealEstate',
    title:
      'pensionProvision.onboarding.stepTwo.lifeSituationsOptions.purchaseOfRealEstate',
  },
  {
    value: 'enteringRetirement',
    title:
      'pensionProvision.onboarding.stepTwo.lifeSituationsOptions.enteringRetirement',
  },
  {
    value: 'illnes',
    title: 'pensionProvision.onboarding.stepTwo.lifeSituationsOptions.illnes',
  },
  {
    value: 'deathOfARelative',
    title:
      'pensionProvision.onboarding.stepTwo.lifeSituationsOptions.deathOfARelative',
  },
  {
    value: 'foundingACompany',
    title:
      'pensionProvision.onboarding.stepTwo.lifeSituationsOptions.foundingACompany',
  },
  {
    value: 'divorce',
    title: 'pensionProvision.onboarding.stepTwo.lifeSituationsOptions.divorce',
  },
];
